






































































































































































































































































import Decimal from 'decimal.js';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import camelcaseKeys from 'camelcase-keys';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { formatNumber } from '../../../utils/number-formatting';

import GoodsReceivedNoteMixin from './goods-received-note-mixin.vue';

import {
  lookupProduct,
  getAvailableInventories,
  getAvailableStock,
} from '../api/goods-received-notes-api';

@Component
export default class extends mixins(GoodsReceivedNoteMixin) {
  @Prop() dataIntrareValue: any;
  @Prop() vatPercentageOptions: any;
  @Prop() allInventoryOptions: any;
  @Prop() items!: Array<any>;

  isAddLine = false;

  get modalTitle(): string {
    if (this.isAddLine) {
      return 'Adaugă poziție';
    }
    return 'Modifică poziție';
  }

  editInventory = {
    description: '', inventoryId: 0, isActive: true, label: '1', type: 'FUR',
  }
  editSelectedProduct = {
    description: '', isActive: true, label: '', measurementUnit: 'BUC', productId: 1,
  }

  editInventoryId: Number = 0;
  editInventoryName: string|null = null;
  editInventoryCode: string|null = null;
  editProductId: Number = 0;
  editProductDescription: string|null = null;
  editLineNotes: string|null = null;
  editLineNumber: Number = 0;
  editLineValue: string|null = null;
  editLineVatValue: string|null = null;
  editMeasurementUnit: string|null = null;
  editReceivedQuantity: string|null = null;
  editSourceDocumentQuantity: string|null = null;
  editUnitPrice: string|null = null;

  editVatPercentage = { label: '19 %', key: 0.19 };

  editMultiselectProductLoading: boolean = false;
  editMultiselectProductList: Array<any> = [];

  editIsInventoryOptionsLoading: boolean = false;
  editAllInventoryOptions: Array<any> = [];
  editInventoryOptions: Array<any> = [];

  editReceivedBy: string|null = null;
  editReceivingCommitteeMembers: Array<any> = [];

  debouncedSearchProducts = debounce(this.editAsyncSearchProducts, 75, { maxWait: 150 });
  debouncedSearchInventory = debounce(this.editAsyncSearchInventory, 75, { maxWait: 150 });

  async editAsyncSearchProducts(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.editMultiselectProductLoading = true;

    this.editMultiselectProductList = camelcaseKeys(await lookupProduct(query));

    this.editMultiselectProductLoading = false;
    return Promise.resolve();
  }

  async editAsyncSearchInventory() {
    if (this.editAllInventoryOptions.length) {
      this.editInventoryOptions = this.editAllInventoryOptions.filter((o) => o.isUserAssociated);
      return Promise.resolve();
    }

    this.editIsInventoryOptionsLoading = true;

    this.editAllInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.editInventoryOptions = this.editAllInventoryOptions.filter((o) => o.isUserAssociated);

    this.editIsInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  onEditInventorySelected(option) {
    if (!option) {
      return;
    }

    if (option.receivingCommitteeMembers && !this.editReceivingCommitteeMembers) {
      this.editReceivingCommitteeMembers = option.receivingCommitteeMembers;
    }

    if (option.storekeeper && !this.editReceivedBy) {
      this.editReceivedBy = option.storekeeper;
    }
  }

  onEditLineValueChanged(value) {
    this.editLineValue = value;
    if (!this.editSourceDocumentQuantity) {
      this.editSourceDocumentQuantity = '1';
      this.editReceivedQuantity = '1';
    }

    const lineValueDecimal = new Decimal(this.editLineValue || '0');
    this.editUnitPrice = lineValueDecimal.div(this.editSourceDocumentQuantity).toFixed(5);
    this.editLineVatValue = this.editVatPercentage
      ? lineValueDecimal.times(this.editVatPercentage.key).toFixed(2)
      : '0';
  }

  onEditVatPercentageChanged(value) {
    this.editVatPercentage = value;
    if (!this.editVatPercentage) {
      this.editLineVatValue = '0';
    } else {
      this.editLineVatValue = new Decimal(this.editLineValue || '0').times(value.key).toFixed(2);
    }
  }

  onEditUnitPriceChanged(value) {
    this.editUnitPrice = value;
    if (!this.editSourceDocumentQuantity) {
      this.editSourceDocumentQuantity = '1';
      this.editReceivedQuantity = '1';
    }

    const lineValueDecimal = new Decimal(this.editUnitPrice || '0').times(this.editReceivedQuantity || '0').toDecimalPlaces(2);
    this.editLineValue = lineValueDecimal.toFixed(2);
    this.editLineVatValue = this.editVatPercentage
      ? lineValueDecimal.times(this.editVatPercentage.key).toFixed(2)
      : '0';
  }

  onEditReceivedQuantityChanged(value) {
    this.editReceivedQuantity = (!value) ? '0' : value;
    if (!this.editSourceDocumentQuantity) {
      this.editSourceDocumentQuantity = new Decimal(value).toFixed(3);
    }
    if (!this.editUnitPrice) {
      this.editUnitPrice = '0';
    }

    const lineValueDecimal = new Decimal(this.editUnitPrice || '0').times(this.editReceivedQuantity || '0').toDecimalPlaces(2);
    this.editLineValue = lineValueDecimal.toFixed(2);
    this.editLineVatValue = this.editVatPercentage
      ? lineValueDecimal.times(this.editVatPercentage.key).toFixed(2)
      : '0';
  }

  onEditSourceDocumentQuantityChanged(value) {
    this.editSourceDocumentQuantity = (!value) ? '0' : value;
    this.onEditReceivedQuantityChanged(value);
  }

  onEditProductSelected(value) {
    if (!value) {
      return;
    }

    this.editProductDescription = value.description;
    this.editMeasurementUnit = value.measurementUnit;

    const wantedVatPercentage = this.vatPercentageOptions.find((o) => o.key === value.vatPercentage);
    if (wantedVatPercentage) {
      this.editVatPercentage = wantedVatPercentage;
    }

    (this.$refs.editProductDescriptionInputControl as any).focus();
  }

  onEditNoteLineModalCancel() {
    this.cleanEditLineInput();
    (<any>(this.$refs.modal)).hide();
  }

  async onEditNoteLineModalOk(event) {
    event.preventDefault();
    const itemLine = await this.onSubmitChanges();

    if (itemLine !== null) {
      this.cleanEditLineInput();
      this.$emit('onEditLineEvent', {
        itemLine,
        receivedBy: this.editReceivedBy,
        receivingCommitteeMembers: this.editReceivingCommitteeMembers,
      });
      (<any>(this.$refs.modal)).hide();
    }
  }

  async onSubmitChanges() {
    const errors: Array<string> = [];

    const itemLine = {
      inventoryCode: this.editInventory?.label,
      inventoryName: this.editInventory?.description,
      inventoryId: this.editInventory?.inventoryId,
      lineNotes: this.editLineNotes,
      lineNumber: this.editLineNumber,
      lineValue: this.editLineValue,
      lineVatValue: this.editLineVatValue,
      measurementUnit: this.editMeasurementUnit,
      productCode: this.editSelectedProduct?.label,
      productId: this.editSelectedProduct?.productId,
      productDescription: this.editProductDescription,
      receivedQuantity: this.editReceivedQuantity!,
      sourceDocumentQuantity: this.editSourceDocumentQuantity,
      unitPrice: this.editUnitPrice,
      vatPercentage: this.editVatPercentage.key,
    };

    if (!itemLine.inventoryId) {
      errors.push('Gestiune neselectată');
    }

    // For now only allow inventoried entries
    if (!itemLine.productId) {
      errors.push('Produs neselectat');
    }
    if (!itemLine.measurementUnit) {
      errors.push('U.M. necompletată');
    }
    if (!itemLine.sourceDocumentQuantity) {
      errors.push('Cantitate furnizor necompletată');
    }
    if (!itemLine.receivedQuantity) {
      errors.push('Cantitate primită necompletată');
    }
    if (!itemLine.unitPrice) {
      errors.push('Preț necompletat');
    }
    if (!this.editVatPercentage) {
      errors.push('Procent TVA necompletat');
    }
    if (itemLine.unitPrice && new Decimal(itemLine.unitPrice).lt(0) && !!itemLine.inventoryId) {
      errors.push('Prețul de intrare în gestiune nu poate fi negativ');
    }

    if (itemLine.unitPrice && new Decimal(itemLine.unitPrice).eq(0) && !!itemLine.inventoryId) {
      if (!this.$bvModal.msgBoxConfirm('Confirmi înregistrarea poziției la prețul de gestiune de zero lei?')) {
        return null;
      }
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'grnDraftLineAddError');
      return null;
    }

    let totalReceived = new Decimal(itemLine.receivedQuantity);
    this.items.filter((e) => e.productId === itemLine.productId
      && e.inventoryId === itemLine.inventoryId
      && e.lineNumber !== itemLine.lineNumber)
      .forEach((item) => {
        const value = new Decimal(item.receivedQuantity);
        totalReceived = totalReceived.plus(value);
      });

    if (new Decimal(totalReceived).lt(0)) {
      const availableStock = await getAvailableStock({
        lookupDate: format(parse(this.dataIntrareValue, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd'),
        productId: itemLine.productId,
        inventoryId: itemLine.inventoryId,
        unitPrice: new Decimal(itemLine.unitPrice || '0').toNumber(),
      });

      if (new Decimal(totalReceived).times(-1).gt(availableStock.totalQuantity)) {
        this.$bvModal.msgBoxOk(`Stoc insuficient în gestiune (${formatNumber(availableStock.totalQuantity, 3)} unități) pentru produsul selectat!`);
        return null;
      }
    }

    return itemLine;
  }

  cleanEditLineInput() {
    this.editInventoryId = 0;
    this.editInventoryName = null;
    this.editInventoryCode = null;
    this.editProductId = 0;
    this.editProductDescription = null;
    this.editLineNotes = null;
    this.editLineNumber = 0;
    this.editLineValue = null;
    this.editLineVatValue = null;
    this.editMeasurementUnit = null;
    this.editReceivedQuantity = null;
    this.editSourceDocumentQuantity = null;
    this.editUnitPrice = null;
    this.editVatPercentage = { label: '19 %', key: 0.19 };

    this.editMultiselectProductLoading = false;
    this.editMultiselectProductList = [];
  }

  showModal(line, receivedBy, receivingCommitteeMembers, isAddLine) {
    this.isAddLine = !!isAddLine;

    this.editLineNotes = line.lineNotes;
    this.editLineNumber = line.lineNumber;
    this.editLineValue = line.lineValue;
    this.editLineVatValue = line.lineVatValue;
    this.editMeasurementUnit = line.measurementUnit;
    this.editInventoryId = line.inventoryId;
    this.editInventoryName = line.inventoryName;

    this.editInventory.description = line.inventoryName;
    this.editInventory.inventoryId = line.inventoryId;

    this.editSelectedProduct.productId = line.productId;
    this.editSelectedProduct.description = line.productDescription;
    this.editSelectedProduct.label = line.productCode;

    this.editInventoryCode = line.inventoryCode;
    this.editProductId = line.productId;
    this.editProductDescription = line.productDescription;
    this.editReceivedQuantity = line.receivedQuantity;
    this.editSourceDocumentQuantity = line.sourceDocumentQuantity;
    this.editUnitPrice = line.unitPrice;
    [this.editVatPercentage] = this.vatPercentageOptions.filter((vatP) => vatP.key === line.vatPercentage);

    this.editReceivedBy = receivedBy;
    this.editReceivingCommitteeMembers = receivingCommitteeMembers;
    this.editInventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);

    (<any>(this.$refs.modal)).show();
  }
}
