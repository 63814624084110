
import Vue from 'vue';
import accounting from 'accounting';
import format from 'date-fns/format';
import Component from 'vue-class-component';
import { toasts } from '@/utils/toasts';

@Component
export default class GoodsReceivedNoteMixin extends Vue {
  decimalNPlacesFormatter(input: string | number, decimalPlaces: number) {
    // Copied from numeric-input.vue / valoareFormatter
    // const settings = { precision: this.precision, decimal: this.decimalSeparator, thousand: '' }
    const settings = { precision: decimalPlaces, decimal: '.', thousand: '' };
    // check if no value supplied and return null if so
    if (typeof input === 'string' && input.length === 0) {
      return null;
    }
    const actualInputAsString = typeof input === 'number' ? input.toString(10) : input;
    // let decimalSeparator = this.decimalSeparator
    let decimalSeparator = '.';
    const hasDot = actualInputAsString.indexOf('.') !== -1;
    const hasComma = actualInputAsString.indexOf(',') !== -1;
    if (hasDot && !hasComma) {
      decimalSeparator = '.';
    } else if (hasComma && !hasDot) {
      decimalSeparator = ',';
    } else {
      // decimalSeparator = this.decimalSeparator
      decimalSeparator = '.';
    }
    return accounting.formatNumber(accounting.unformat(actualInputAsString, decimalSeparator), settings);
  }
  dateFormatter(value, fmt) {
    return format(value, fmt);
  }
  decimal2PlacesFormatter(input: string | number): string | null {
    return this.decimalNPlacesFormatter(input, 2);
  }
  decimal3PlacesFormatter(input: string | number): string | null {
    return this.decimalNPlacesFormatter(input, 3);
  }
  decimal5PlacesFormatter(input: string | number): string | null {
    return this.decimalNPlacesFormatter(input, 5);
  }
  showErrorsToast(errors, toastId: string|undefined) {
    const h = this.$createElement;
    const vNodesMsg = [
      h('p', {}, 'Trebuie corectate următorele erori:'),
      h(
        'ul',
        {},
        errors.map((e) => h('li', {}, e)),
      )];
    toasts.error(vNodesMsg, {
      title: 'Eroare de validare',
      id: toastId,
    });
  }
}
