








































































































































































import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import Component, { mixins } from 'vue-class-component';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import GoodsReceivedNoteMixin from './goods-received-note-mixin.vue';

extend('afterOrEqual', (value, afterValue) => parse(value, 'dd / MM / yyyy', new Date()) >= parse(afterValue[0], 'dd / MM / yyyy', new Date()));

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class extends mixins(GoodsReceivedNoteMixin) {
  dataDocumentValue = '20 . 12 . 2020';
  dataScadentaValue = '20 . 12 . 2020';

  dataIntrareValueDatepicker = '2020-12-20';
  dataDocumentValueDatepickerMax = '2099-12-31';
  dataScadentaValueDatepickerMin = '2000-01-01';

    sourceDocumentTypeOptions = [
      { key: 'FAF', label: 'Factură' },
      { key: 'FAV', label: 'Aviz' },
      { key: 'FSM', label: 'Factură simpificată' },
      { key: 'BON', label: 'Bon fiscal' },
      { key: 'OTH', label: 'Altele' },
    ];

  isDraft = true;
  sourceDocumentType = { key: 'FAF', label: 'Factură' };
  sourceDocumentNumber = '';
  dataDocumentValueDatepicker: Date|string = '2020-12-20';
  dataScadentaValueDatepicker: Date|string = '2020-12-20';

  dateFormatterDataDocument(value) {
    if (value) {
      this.dataDocumentValueDatepicker = format(parse(value, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd');
      this.dataScadentaValueDatepickerMin = this.dataDocumentValueDatepicker;
      return format(parse(value, 'dd / MM / yyyy', new Date()), 'dd / MM / yyyy');
    }
    return null;
  }

  dateFormatterDataScadenta(value) {
    if (value) {
      this.dataScadentaValueDatepicker = format(parse(value, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd');
      return format(parse(value, 'dd / MM / yyyy', new Date()), 'dd / MM / yyyy');
    }
    return null;
  }

  onContextDataDocument(ctx) {
    this.dataDocumentValue = format(parse(ctx.selectedYMD, 'yyyy-MM-dd', new Date()), 'dd / MM / yyyy');
    this.dataScadentaValueDatepickerMin = ctx.selectedYMD;
  }

  onContextDataScadenta(ctx) {
    this.dataScadentaValue = format(parse(ctx.selectedYMD, 'yyyy-MM-dd', new Date()), 'dd / MM / yyyy');
  }

  onModalCancel() {
    this.hideModal();
  }

  onModalOk(evt) {
    evt.preventDefault();

    const errors: Array<string> = [];

    if (!this.sourceDocumentType) {
      errors.push('Tip document neselectat');
    }

    if (!this.sourceDocumentNumber) {
      errors.push('Număr document neselectat');
    }

    if (this.dataScadentaValueDatepicker < this.dataDocumentValueDatepicker) {
      errors.push('Data scadență trebuie sa fie mai mare sau egala cu data documentului');
    }

    if (this.dataIntrareValueDatepicker < this.dataDocumentValueDatepicker) {
      errors.push('Data de intrare trebuie sa fie mai mare sau egala cu data documentului');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'grnEditSourceDocumentDetailsModalError');
      return;
    }

    this.$emit('onModalOk', {
      sourceDocumentTypeCode: this.sourceDocumentType.key,
      sourceDocumentNumber: this.sourceDocumentNumber,
      sourceDocumentDate: this.dataDocumentValueDatepicker,
      sourceDocumentDueDate: this.dataScadentaValueDatepicker,
    });
  }

  async hideModal() {
    await (<any>(this.$refs.editSourceDocumentDetailsModal)).hide();
  }

  async showModal(sourceDocumentData) {
    this.sourceDocumentType = sourceDocumentData.sourceDocumentType;
    this.sourceDocumentNumber = sourceDocumentData.sourceDocumentNumber;
    this.dataDocumentValueDatepicker = sourceDocumentData.dataDocumentValueDatepicker;
    this.dataScadentaValueDatepicker = sourceDocumentData.dataScadentaValueDatepicker;
    this.dataIntrareValueDatepicker = sourceDocumentData.dataIntrareValueDatepicker;

    (<any>(this.$refs.editSourceDocumentDetailsModal)).show();
  }
}
