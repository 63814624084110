




































































































































































































































































import camelcaseKeys from 'camelcase-keys';
import Decimal from 'decimal.js';
import Component, { mixins } from 'vue-class-component';
import { getAccountingInvoice, getAvailableAccounts } from '../api/goods-received-notes-api';
import GoodsReceivedNoteMixin from './goods-received-note-mixin.vue';

@Component
export default class extends mixins(GoodsReceivedNoteMixin) {
  get Decimal() {
    return Decimal;
  }

  sourceDocumentFields: Array<any> = [
    {
      key: 'selected',
      label: 'Sel.',
      thClass: 'text-center',
      tdClass: 'text-center pl-2',
    },
    {
      key: 'numar',
      label: 'Nr. crt.',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'explicatie',
      label: 'Explicatie',
      thClass: 'tbl-col-product-published',
      tdClass: 'py-2 tbl-col-product-published',
    },
    {
      key: 'valoareFurnizor',
      label: 'Val. furnizor',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'valoareTva',
      label: 'Val. / % TVA',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'contDebitor',
      label: 'Conturi',
      thClass: 'tbl-col-product-published',
      tdClass: 'py-2 tbl-col-product-published',
    },
  ]

  syncAccountingFields: Array<any> = [
    {
      key: 'selected',
      label: 'Sel.',
      thClass: 'text-center',
      tdClass: 'text-center pl-2',
    },
    {
      key: 'idx',
      label: 'Nr. crt.',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'inventoryCode',
      label: 'Gest.',
      thClass: 'tbl-col-product-published',
      tdClass: 'py-2 tbl-col-product-published',
    },
    {
      key: 'product',
      label: 'Produs',
      thClass: 'tbl-col-product-published',
      tdClass: 'py-2 tbl-col-product-published',
    },
    {
      key: 'receivedQuantity',
      label: 'Cant./Preț',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'lineValue',
      label: 'Valoare',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'lineVatValue',
      label: 'Val. / % TVA',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'productAccountNumber',
      label: 'Cont gest.',
      thClass: 'tbl-col-inventory-account',
      tdClass: 'tbl-col-inventory-account',
    },
    {
      key: 'creditAccount',
      label: 'Cont cred.',
      thClass: 'tbl-col-expense-account',
      tdClass: 'tbl-col-expense-account',
    },
  ];

  syncAccountingItems: Array<any> = [];

  syncAccountingCreditAccounts: Array<any> = [];
  syncAccountingCreditAccountsFiltered: Array<any> = [];

  goodsReceivedNoteId: Number = 0;

  sourceDocument: any = null;
  sourceDocumentLines: Array<any> = [];
  sourceDocumentExistingAllocations: Array<any> = [];

  onPozitieIntrareFurnizorChanged() {
    for (let idx = 0; idx < this.sourceDocumentLines.length; idx += 1) {
      const line = this.sourceDocumentLines[idx];

      const associatedGoodsReceivedNoteLines = this.syncAccountingItems.filter(
        (item) => item.pozitieIntrareFurnizor
        && item.pozitieIntrareFurnizor.pozitieIntrareFurnizorId === line.pozitieIntrareFurnizorId,
      ) || [];

      line.associatedGoodsReceivedNoteLineNumbers = associatedGoodsReceivedNoteLines.map((x) => x.lineNumber).sort((l, r) => l - r);
      line.associatedGoodsReceivedNoteLinesValue = associatedGoodsReceivedNoteLines.reduce((acc, item) => acc.plus(item.lineValue), new Decimal(0)).toNumber();
      line.associatedGoodsReceivedNoteLinesLeftoverValue = new Decimal(line.valoareFurnizor)
        .minus(line.associatedGoodsReceivedNoteAlreadyAllocatedValue)
        .minus(line.associatedGoodsReceivedNoteLinesValue)
        .toNumber();

      // eslint-disable-next-line no-underscore-dangle
      line._showDetails = associatedGoodsReceivedNoteLines.length > 0 || line.associatedGoodsReceivedNoteAlreadyAllocatedValue !== 0.0;
    }
  }

  syncAccountingCreditAccountsSearch(query: string) {
    this.syncAccountingCreditAccountsFiltered = this.syncAccountingCreditAccounts.filter((x) => x.label.toUpperCase().indexOf(query.toUpperCase()) !== -1);
    return Promise.resolve();
  }

  async onSyncAccountingModalCancel(evt) {
    evt.preventDefault();

    if (await this.$bvModal.msgBoxConfirm('Confirmi că vrei să renunți la sinconizarea documentului?')) {
      (<any>(this.$refs.accountingSyncModal)).hide();
    }
  }

  async onSyncAccountingModalOk(evt) {
    const hasEmptyCreditAccount = !!this.syncAccountingItems.find((item) => !item.creditAccount);
    if (hasEmptyCreditAccount) {
      evt.preventDefault();
      await this.$bvModal.msgBoxOk('Nu au fost completate toate conturile de credit!');
      return;
    }

    const hasEmptyLineAllocations = !!this.syncAccountingItems.find((item) => !item.pozitieIntrareFurnizor);
    if (hasEmptyLineAllocations) {
      evt.preventDefault();
      await this.$bvModal.msgBoxOk('Nu au fost alocate toate pozițiile de document!');
      return;
    }

    const hasExceedingValueLineAllocations = !!this.syncAccountingItems.find(
      (item) => 1 - new Decimal(Decimal.sign(item.pozitieIntrareFurnizor.associatedGoodsReceivedNoteLinesLeftoverValue)).toNumber() !== 1 - new Decimal(Decimal.sign(item.pozitieIntrareFurnizor.valoareFurnizor)).toNumber()
      && !new Decimal(item.pozitieIntrareFurnizor.associatedGoodsReceivedNoteLinesLeftoverValue).eq(0.0),
    );
    if (hasExceedingValueLineAllocations) {
      evt.preventDefault();
      await this.$bvModal.msgBoxOk('Sunt poziții de document sursă cu valoarea depășită!');
      return;
    }

    const hasIncorrectCreditAccountAllocations = !!this.syncAccountingItems.find((item) => item.pozitieIntrareFurnizor.contDebitor !== item.creditAccount.numar);
    if (hasIncorrectCreditAccountAllocations) {
      evt.preventDefault();
      await this.$bvModal.msgBoxOk('Sunt poziții de NIR cu contul creditor incorect față de înregistrarea din documentul sursă!');
      return;
    }

    const postData = {
      sourceDocumentId: this.sourceDocument.intrareFurnizorId,
      lines: this.syncAccountingItems.map((item) => ({
        goodsReceivedNoteLineId: item.goodsReceivedNoteLineId,
        sourceDocumentLineId: item.pozitieIntrareFurnizor.pozitieIntrareFurnizorId,
        creditAccountId: item.creditAccount.internalId,
        creditAmount: item.lineValue,
      })),
    };

    this.$emit('onSyncAccountingModalOk', postData);
  }

  async initialize() {
    const allAccounts = camelcaseKeys(await getAvailableAccounts()).map((e) => ({ ...e, label: `${e.numar} - ${e.denumire}` }));
    const { item1, item2, item3 } = camelcaseKeys(await getAccountingInvoice(this.goodsReceivedNoteId), { deep: true });

    if (!item1 || !item2) {
      await this.$bvModal.msgBoxOk('Documentul-sursă nu a putut fi găsit în xConta');
      return false;
    }

    this.sourceDocument = item1;
    this.sourceDocumentLines = item2.map((line) => ({
      ...line,
      _showDetails: false,
      associatedGoodsReceivedNoteLineNumbers: [],
      associatedGoodsReceivedNoteAlreadyAllocatedValue: 0.0,
      associatedGoodsReceivedNoteLinesValue: 0.0,
      associatedGoodsReceivedNoteLinesLeftoverValue: line.valoareFurnizor,
    }));
    this.sourceDocumentExistingAllocations = (item3 || []).map((item) => camelcaseKeys(item, { deep: true }));

    this.sourceDocumentExistingAllocations.forEach((existingAllocation) => {
      const sdl = this.sourceDocumentLines.find((line) => line.pozitieIntrareFurnizorId === existingAllocation.sourceDocumentLineId);
      if (!sdl) {
        return;
      }

      sdl.associatedGoodsReceivedNoteAlreadyAllocatedValue = new Decimal(sdl.associatedGoodsReceivedNoteAlreadyAllocatedValue).plus(existingAllocation.accountingValue).toNumber();
      sdl.associatedGoodsReceivedNoteLinesLeftoverValue = new Decimal(sdl.associatedGoodsReceivedNoteLinesLeftoverValue).minus(existingAllocation.accountingValue).toNumber();
      if (sdl.associatedGoodsReceivedNoteAlreadyAllocatedValue) {
        // eslint-disable-next-line no-underscore-dangle
        sdl._showDetails = true;
      }
    });

    this.syncAccountingCreditAccounts = allAccounts.filter((e) => (e.numar.startsWith(473)));
    this.syncAccountingCreditAccountsFiltered = this.syncAccountingCreditAccounts;

    const defaultCreditAccount = allAccounts.find((e) => e.numar === '4734');
    this.syncAccountingItems = this.syncAccountingItems.map((item) => ({
      creditAccount: defaultCreditAccount || null,
      inventoryAccountNumber: allAccounts.filter((e) => e.internalId === item.inventoryAccountId),
      ...item,
    }));

    return true;
  }

  hideModal() {
    (<any>(this.$refs.accountingSyncModal)).hide();
  }

  async showModal(goodsReceivedNoteId, items) {
    this.goodsReceivedNoteId = goodsReceivedNoteId;
    this.syncAccountingItems = (items || [])
      .filter((item) => !!item.inventoryId)
      .map((item) => ({ _showDetails: true, ...item }));

    const initSuccessful = await this.initialize();
    if (initSuccessful) {
      (<any>(this.$refs.accountingSyncModal)).show();
    }
  }
}
